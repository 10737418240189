.event_detail--menu_image{
    width: 120px;
    height: 120px;
    border-radius: 15px;
    overflow: hidden;
}

.event_detail--menu--head{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
}

.event_detail--menu--item{
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    background-color: none;
    align-self: stretch;
    text-decoration: none;
}

.event_detail--menu--item--selected{
    display: flex;
    padding: 8px 4px 8px 4px;
    align-items: center;
    justify-content: center;
    gap: 6px;
    align-self: stretch;
    background: var(--Light-Grey, #FFF);
    text-decoration: none;
}

.event_detail--actions{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (min-width: 781px) {
    .event_detail--container{
        display: flex;
        flex-direction: column;
        border-radius: 15px;
        gap: 24px;
        flex: 1;
    }
    
    .event_detail--menu{
        background: var(--blue-navy, #19204E);
        width: 150px;
        min-width: 150px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px 12px;
        gap: 48px;
        color: #FFF;
    }

    .event_detail--menu--items{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        width: 100%;
    }
    
    .event_detail--body{
        display: flex;
        flex-direction: row;
        flex: auto;
    }
    
    .event_detail--description{
        background: var(--grey, #D6D6D6);
        display: flex;
        flex-direction: column;
        flex: auto;
        padding: 24px;
        gap: 24px;
    }
    
}

@media screen and (max-width: 780px){
    .event_detail--container{
        display: flex;
        flex-direction: column;
        border-radius: 15px;
        gap: 12px;
        flex: 1;
    }
    
    .event_detail--menu{
        background: var(--blue-navy, #19204E);
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px;
        gap: 24px;
        color: #FFF;
    }

    .event_detail--menu--items{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        width: 100%;
    }
    
    .event_detail--body{
        display: flex;
        flex-direction: column;
        flex: auto;
    }
    
    .event_detail--description{
        background: var(--grey, #D6D6D6);
        display: flex;
        flex-direction: column;
        flex: auto;
        padding: 12px;
        gap: 12px;
    }
    
}
