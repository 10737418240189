
@media screen and (min-width: 781px){
    .chat_list--container{
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}


@media screen and (max-width: 780px){
    .chat_list--container{
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}


