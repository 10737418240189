.main--image--resizable{
    width: 100%;
    min-width: 325px;
    height: auto;
}


/* desktop screen size */
@media only screen and (min-width: 781px) {
    .hero{
        display: flex;
        justify-content: space-between;
        padding: 185px 64px 112px 64px;
        flex-direction: column;
        align-items: center;
        background: var(--Blue-Light, #CCD9E5);
        gap: 80px;
    }

    .hero--texts {
        display: flex;
        align-items: start;    
        justify-content: space-between;
        gap: 80px;
        align-self: stretch;
    }

    .hero--right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }

    .hero-subtitle{
        align-self: stretch;
    }

    .hero--action{
        display: flex;
        padding-top: 16px;
        align-items: flex-start;
        gap: 16px;
    }

    .container{
        display: flex;
        padding: 112px 64px;
        flex-direction: column;
        align-items: flex-start;
        gap: 80px;
    }

    .benefits--content{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 80px;
    }

    .benefits--texts{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        flex: 1 0 0;
    }

    .features--content{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 40px;
    }

    .features--texts{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        flex: 1 1 0;
    }

    .steps--content{
        display: flex;
        align-self: stretch;
        flex-direction: column;
        align-items: center;
        gap: 80px;
    }

    .steps--title{
        max-width: 800px;
        text-align: center;
    }

    .steps--texts{
        display: flex;
        justify-content: space-between;
        gap: 48px;
    }

    .steps--step{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }

    .steps--step--title{
        text-align: center;
    }

    .steps--step--text{
        text-align: center;
    }

    .connect--content{
        display: flex;
        justify-content: space-between;
        gap: 80px;
    }

    .connect--texts{
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}

@media only screen and (max-width: 780px) {
    .hero{
        display: flex;
        padding: 125px 32px 56px 32px;
        flex-direction: column;
        align-items: center;
        gap: 48px;
        background: var(--Blue-Light, #CCD9E5);
    }

    .hero--texts {
        display: flex;
        align-items: start;    
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        align-self: stretch;
    }

    .hero--right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }

    .hero-subtitle{
        align-self: stretch;
    }

    .hero--action{
        display: flex;
        padding-top: 16px;
        align-items: flex-start;
        gap: 16px;
    }

    .container{
        display: flex;
        padding: 64px 20px;
        flex-direction: column;
        gap: 48px;
        align-self: stretch;
    }

    .benefits--content{
        display: flex;
        justify-content: space-between;
        gap: 48px;
        align-items: center;
        flex-direction: column;
    }

    .benefits--texts{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        flex: 1 0 0;
        min-width: 325px;
    }

    .features--content{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }

    .features--texts{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }

    .steps--content{
        display: flex;
        flex-direction: column;
        gap: 48px;
    }

    .steps--title{
        max-width: 800px;
    }

    .steps--texts{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
    }

    .steps--step{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 500px;
        min-width: 325px;
        gap: 12px;
    }

    .steps--step--title{
        text-align: left;
    }

    .steps--step--text{
        text-align: left;
    }

    .connect--content{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 48px;
    }

    .connect--texts{
        display: grid;
        align-self: normal;
        gap: 12px;
    }
}