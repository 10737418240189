/* desktop screen size */
@media only screen and (min-width: 781px) {
    .app_head {
        display: flex;
        padding: 12px 32px;
        justify-content: space-between;
        align-items: center;
        background: var(--Blue-Dark, #19204E);
        box-sizing: border-box;
        position: fixed;
        width: 100%;
        z-index: 1;
    }

    .app_head--head{
        display: flex;
        align-items: center;
    }

    .app_head--actions{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 32px;
    }

    .app_head--icon{
        color: #FFF;
        width: 36px;
        height: 36px;
        
    }

    .hide--desktop{
        display: none;
    }
}


/* tablet screen size */
@media only screen and (max-width: 780px) {

    .app_head {
        display: flex;
        padding: 12px 32px;
        justify-content: space-between;
        align-items: center;
        background: var(--Blue-Dark, #19204E);
        box-sizing: border-box;
        position: fixed;
        z-index: 100000;
    }

    .app_head--head{
        display: flex;
        align-items: center;
    }

    .app_head--actions{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-self: stretch;
        align-items: center;
        gap: 24px;
        z-index: 100;
    }

    .app_head--right{
        display: flex;
        gap: 24px;
        
    }

    .app_head--icon{
        color: #FFF;
        width: 24px;
        height: 24px;
    }

    .app_head--menu{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-self: stretch;
        align-items: center;
        gap: 12px;
        padding: 24px;
    }

    .app_head {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 12px 12px;
        justify-content: space-between;
        align-items: flex-start;
        position: fixed;
        background: var(--Blue-Dark, #19204E);
        box-sizing: border-box;
        gap: 24px;
    }

    .app_head--head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
    }

    .app_head--actions{
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-self: stretch;
        align-items: flex-start;
        gap: 12px;
    }

    .app_head--menu{
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-self: stretch;
        align-items: flex-start;
        gap: 16px;
    }

    .app_head--menu--item{
        color: #FFF;
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: none;
        align-self: stretch;
        text-decoration: none;
    }

    .app_head--menu--item--selected{
        display: flex;
        padding: 6px 0px 6px 6px;
        margin: 0px -15px 0px -15px;
        align-items: center;
        gap: 6px;
        align-self: stretch;
        border-radius: 50px;
        background: var(--Light-Grey, #F4F4F4);
        text-decoration: none;
    }

    .app_head--menu--icon--selected{
        width: 36px;
        height: 36px;
        flex-shrink: 0;
        border-radius: 25px;
        background: linear-gradient(136deg, #19204E 4.8%, #6980FD 99.39%);
        color: #FCB300;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .app_navbar--menu--icon{
        display: flex;
        align-items: center;
    }

    .app_head--buttons{
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-self: stretch;
        align-items: flex-start;
        gap: 12px;
    }

    .logo--size{
        width: 89px;
        height: 34px;
    }
 
    .hide--mobile{
        display: none;
    }
}
