/* desktop screen size */
@media only screen and (min-width: 781px) {
    .navbar {
        display: flex;
        width: 100%;
        padding: 12px 32px;
        justify-content: space-between;
        align-items: center;
        background: var(--Blue-Dark, #19204E);
        box-sizing: border-box;
        position: fixed;
    }

    .navbar--head{
        display: flex;
        align-items: center;

    }

    .navbar--actions{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 32px;
    }

    .navbar--menu{
        display: flex;
        align-items: flex-start;
        gap: 32px;
    }

    .navbar--buttons{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }

    .logo--size{
        width: 129px;
        height: 50px;
    }

    .hide--desktop{
        display: none;
    }
}


/* tablet screen size */
@media only screen and (max-width: 780px) {
    .navbar {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 12px 12px;
        justify-content: space-between;
        align-items: flex-start;
        position: fixed;
        background: var(--Blue-Dark, #19204E);
        box-sizing: border-box;
        gap: 24px;
    }

    .navbar--head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
    }

    .navbar--actions{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-self: stretch;
        align-items: center;
        gap: 32px;
    }

    .navbar--menu{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-self: stretch;
        align-items: center;
        gap: 12px;
    }

    .navbar--buttons{
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: flex-start;
        gap: 12px;
    }

    .logo--size{
        width: 89px;
        height: 34px;
    }
 
    .hide--mobile{
        display: none;
    }
}

/* mobile screen size */
@media only screen and (max-width: 490px) {
    .navbar {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 12px 12px;
        justify-content: space-between;
        align-items: flex-start;
        position: fixed;
        background: var(--Blue-Dark, #19204E);
        box-sizing: border-box;
        gap: 24px;
    }

    .navbar--head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
    }

    .navbar--actions{
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-self: stretch;
        align-items: flex-start;
        gap: 12px;
    }

    .navbar--menu{
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-self: stretch;
        align-items: flex-start;
        gap: 12px;
    }

    .navbar--buttons{
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-self: stretch;
        align-items: flex-start;
        gap: 12px;
    }

    .logo--size{
        width: 89px;
        height: 34px;
    }
 
    .hide--mobile{
        display: none;
    }
}
