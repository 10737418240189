.profile_skill_item--container{
    display: flex;
    min-width: 240px;
    max-width: 320px;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1;
    border-radius: 15px;
    background: var(--grey-light, #F5F5F5);
    height: max-content;
}

.profile_skill_item--head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    width: 100%;    
}

.profile_skill_item--content{
    border-top: #19204E 1px solid;
    padding-top: 8px;
    border-spacing: 8px;
}

.profile_skill_item--content--row{
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: space-between;
}

.profile_skill_item--star{
    width: 24px;
    height: 24px;
    color: #FCB300;
}

.profile_skill_item--star-disabled{
    width: 24px;
    height: 24px;
    color: #a5a5a5;
}
