
@media screen and (min-width: 781px) {
    
    .app--content{
        display: flex;
        flex-direction: column;
        padding: 120px 48px 48px 250px;
        overflow: hidden;
    }
    
}


@media screen and (max-width: 780px){
    
    .app--content{
        display: flex;
        flex-direction: column;
        padding: 72px 12px 96px 12px;
        gap: 24px;
        overflow: hidden;
    }
    
}