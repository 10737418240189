body {
  margin: 0;
  font-family: 'Lexend', 'Oxygen', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* desktpo screen size */
@media only screen and (min-width: 781px) {

  .text--heading--h1{
    /* color: var(--Black, #000); */
  
    /* Heading/H1 */
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 67.2px */
  }
  
  .text--heading--h2{
    /* color: var(--Black, #000); */
  
    /* Heading/H1 */
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 67.2px */
  }
  
  .text--heading--h3{
    /* color: var(--Black, #000); */
  
    /* Heading/H3 */
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 48px */
  }
  
  .text--heading--h4{
    /* color: var(--Black, #000); */
  
    /* Heading/H3 */
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 48px */
  }
  
  .text--heading--h5{
    /* color: var(--Black, #000); */
  
    /* Heading/H3 */
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    word-wrap: break-word;
  }
  
  .text--large--bold{
    /* color: black; */
    font-size: 20px;
    font-weight: 700;
    line-height: 150%;
    word-wrap: break-word;
  }

  .text--medium--bold{
    /* color: black; */
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
    word-wrap: break-word;
  }

  .text--regular--bold{
    /* color: black; */
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    word-wrap: break-word;
  }

  .text--small--bold{
    /* color: black; */
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
    word-wrap: break-word;
  }

  .text--xsmall--bold{
    /* color: black; */
    font-size: 12px;
    font-weight: 700;
    line-height: 150%;
    word-wrap: break-word;
  }

  .text--large--normal{
    /* color: black; */

    /* Text/Medium/Normal */
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }

  .text--medium--normal{
    /* color: black; */

    /* Text/Medium/Normal */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }

  .text--regular--normal{
    /* color: var(--Light-Grey, #000); */

    /* Text/Regular/Normal */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }

  .text--small--normal{
    /* color: black; */

    /* Text/Small/Normal */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }

  .text--xsmall--normal{
    /* color: black; */

    /* Text/Small/Normal */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }

  .image--resizable{
    width: 100%;
    max-width: 600px;
    min-width: 325px;
    height: fit-content;
    border-radius: 25px;
  }

  .icon_button{
    color: var(--Red, #DF1B3F);
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 4px;
    font-size: 24px;
    text-decoration: none;
  }

  .icon_button svg{
    border: solid 1px  #DF1B3F;
    width: 24px;
    height: 24px;
    color: #DF1B3F;
    border-radius: 5px;
    padding: 2px;
  }  


  .button--secondary{
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border: 1px solid var(--Red, #DF1B3F);
    color: var(--Red, #DF1B3F);
    background-color: transparent;
    text-wrap: nowrap;
  }
  
  .button--disabled{
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    color: var(--White, #a0a0a0);
    border-radius: 25px;
    border: 1px solid var(--Red, #a0a0a0);
    background: var(--Red, #F5F5F5);
    text-wrap: nowrap;
    width: fit-content;
    gap: 8px;
  }

  .button--primary{
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    color: var(--White, #FFF);
    border-radius: 25px;
    border: 1px solid var(--Red, #DF1B3F);
    background: var(--Red, #DF1B3F);
    text-wrap: nowrap;
    width: fit-content;
    gap: 8px;
  
    /* medium */
    /* box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.03), 0px 12px 16px -4px rgba(0, 0, 0, 0.08); */
  }

  .button--primary svg{
    width: 24px;
    height: 24px;
  }
  
  .modal--container{
    margin-top: 120px;
  }  

  .form--row{
    display: flex;
    justify-content: space-between;
    gap: 12px;
    flex: 1;
    flex-wrap: wrap;
  }
}

/* tablet screen size */
@media only screen and (max-width: 780px) {

  .text--heading--h1{
    /* color: black; */
    /* Heading/H1 - Mobile */
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 48px */
  }
  
  .text--heading--h2{
    /* color: black; */
  
    /* Heading/H1 */
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 67.2px */
  }
  
  .text--heading--h3{
    /* color: black; */
    /* Heading/H3 - Mobile */
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 48px */
  }

  .text--heading--h4{
    /* color: var(--Black, #000); */
  
    /* Heading/H3 */
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 48px */
  }
  
  .text--heading--h5{
    /* color: black; */
  
    /* Heading/H5 */
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    word-wrap: break-word;
  }

  .text--large--bold{
    /* color: black; */
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
    word-wrap: break-word;
  }
  
  .text--medium--bold{
    /* color: black; */
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    word-wrap: break-word;
  }

  .text--regular--bold{
    /* color: black; */
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
    word-wrap: break-word;
  }

  .text--small--bold{
    /* color: black; */
    font-size: 12px;
    font-weight: 700;
    line-height: 150%;
    word-wrap: break-word;
  }

  .text--xsmall--bold{
    /* color: black; */
    font-size: 10px;
    font-weight: 700;
    line-height: 150%;
    word-wrap: break-word;
  }

  .text--large--normal{
    /* color: black; */
  
    /* Text/Medium/Normal */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }
  
  .text--medium--normal{
    /* color: black; */
  
    /* Text/Medium/Normal */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }
  
  .text--regular--normal{
    /* color: black; */
  
    /* Text/Regular/Normal */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }
  
  .text--small--normal{
    /* color: black; */
  
    /* Text/Small/Normal */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }
    
  .text--xsmall--normal{
    /* color: black; */
  
    /* Text/Small/Normal */
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }
    
  .image--resizable{
    width: 100%;
    max-width: 600px;
    min-width: 325px;
    height: fit-content;
    border-radius: 15px;
  }

  .icon_button{
    color: var(--Red, #DF1B3F);
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    font-size: 20px;
    text-decoration: none;
  }  

  .icon_button svg{
    border: solid 1px  #DF1B3F;
    width: 24px;
    height: 24px;
    color: #DF1B3F;
    border-radius: 5px;
    padding: 2px;

  }  

  .button--secondary{
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border: 1px solid var(--Red, #DF1B3F);
    color: var(--Red, #DF1B3F);
    background-color: transparent;
    text-wrap: nowrap;
  }
  
  .button--disabled{
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    color: var(--White, #a0a0a0);
    border-radius: 25px;
    border: 1px solid var(--Red, #a0a0a0);
    background: var(--Red, #F5F5F5);
    text-wrap: nowrap;
    width: fit-content;
    gap: 4px;
  
    /* medium */
    /* box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.03), 0px 12px 16px -4px rgba(0, 0, 0, 0.08); */
  }

  .button--primary{
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    color: var(--White, #FFF);
    border-radius: 25px;
    border: 1px solid var(--Red, #DF1B3F);
    background: var(--Red, #DF1B3F);
    text-wrap: nowrap;
    width: fit-content;
    gap: 4px;
  
    /* medium */
    /* box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.03), 0px 12px 16px -4px rgba(0, 0, 0, 0.08); */
  }

  .button--primary svg{
    width: 16px;
    height: 16px;
  }
  
  .form--row{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    flex-wrap: wrap;
  }
}

.icons {
  color: white;
  width: 32px;
  height: 32px;
}

.button--social{
  font-size: small;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  border: none;
  gap: 8px;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.17), 0px 0px 3px 0px rgba(0, 0, 0, 0.08);
}

.google{
  background: #FFF;
}

.facebook{
  background: #1877F2;
  color: var(--White, #FFF);
}

.form--container{
  display: flex;
  flex-direction: column;
  background: var(--grey-light, #F5F5F5);
  border-radius: 15px;
  padding: 12px;
  gap: 12px;
  flex: 1;
}

form{
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.form--fieldset{
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}

.form--fieldset--checkbox{
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: left;
  flex-wrap: wrap;
  flex: 1 1 1;
  margin-right: 8px;
}

.form--action_row{
  display: flex;
  justify-content: center;
  gap: 12px;
  flex: 1;
  flex-wrap: wrap;
}

/* form input[type='text'], input[type='url'], input[type='date'], input[type='email'], input[type='number'], input[type='search'], input[type='password'] {
  border-radius: 4px;
  padding: 10px 8px;
  border: solid 1px hsl(0, 0%, 80%);
  min-width: 200px;
  height: min-content;
  flex: 1 1 1;
} */

/* form input[type='checkbox'] {
  border-radius: 4px;
  padding: 10px 8px;
  border: solid 1px hsl(0, 0%, 80%);
}

form textarea {
  border-radius: 4px;
  padding: 10px 8px;
  border: solid 1px hsl(0, 0%, 80%);
}

form select {
  border-radius: 4px;
  padding: 10px 8px;
  border: solid 1px hsl(0, 0%, 80%);
  min-width: 200px;
} */

.form_select{
  min-width: 200px;
  z-index: 99;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

a:visited {
  color: inherit;
  text-decoration: none;
}

.form--error-fieldset{
  display: flex;
  flex-direction: column;
  gap: 0px;
  flex: 1;
}

.error-text{
  color: #DF1B3F;
  align-self: flex-start;
  font-size: x-small;
}

input.error-input{
  border: #DF1B3F 1px solid;
}

