@media screen and (min-width: 781px) {
    .event_list--filter_bar--container{
        display: flex;
        justify-content: space-between;
        background-color: #19204E;
        border-radius: 15px;
        flex: 1;
        padding: 12px;
        gap: 12px;
        flex-wrap: wrap;
    }
    .event_list--filter_bar--group{
        display: flex;
        gap: 6px;
        flex: 1;
    }

    .event_list--container{
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .event_list--content{
        display: flex;
        flex-direction: row;
        gap: 24px;
    }

    .event_list--list_area{
        display: flex;
        flex-direction: column;
        gap: 24px;
        flex: 1;
    }

    .event_list--map_area{
        display: flex;
        flex-direction: column;
        border-radius: 15px;
        overflow: hidden;
        flex: 1;
        height: 600px;
    }

    .event_list--switch_map_list{
        display: flex;
        justify-content: space-between;
        gap: 24px;
        padding: 12px;
        background: var(--grey-light, #F5F5F5);
        border-radius: 15px;
    }

    .event_list--switch_map_list--actions{
        display: flex;
        gap: 12px;
        align-items: center;
    }

    /* .event_list--switch_map_list--actions svg{
        width: 24px;
        height: 24px;
        border: #DF1B3F 1px solid;
        border-radius: 50px;
        padding: 4px;
        color: #FFF;
    } */
    
}

@media screen and (max-width: 780px){
    .event_list--filter_bar--container{
        display: flex;
        justify-content: space-between;
        background-color: #19204E;
        border-radius: 15px;
        flex: 1;
        padding: 12px;
        gap: 12px;
    }

    .event_list--filter_bar--group{
        display: flex;
        gap: 6px;
        flex: 1;
    }

    .event_list--container{
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .event_list--content{
        display: flex;
        flex-direction: column;
    }

    .event_list--list_area{
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .event_list--map_area{
        display: flex;
        flex-direction: column;
        border-radius: 15px;
        overflow: hidden;
        height: 600px;
    }

    .event_list--switch_map_list{
        display: flex;
        justify-content: space-between;
        gap: 24px;
        padding: 12px;
        background: var(--grey-light, #F5F5F5);
        border-radius: 15px;
    }

    .event_list--switch_map_list--actions{
        display: flex;
        align-items: center;
        gap: 12px;
    }

    /* .event_list--switch_map_list--actions svg{
        width: 24px;
        height: 24px;
        border: #FFF 1px solid;
        border-radius: 50px;
        padding: 2px;
        color: #FFF;
    } */
    
}


.event_list--modal--container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    gap: 12px;
}

.event_list--modal--head{
    color: #000;
    display: flex;
    justify-content: right;
    margin-bottom: 12px;
}

.event_list--modal--footer{
    color: #000;
    display: flex;
    justify-content: center;
    flex: 1;
    gap: 24px;
    padding: 24px;
}


