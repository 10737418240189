.profile_history_form--container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px;
    flex: 1;
}

.profile_history_form--form{
    display: flex;
    flex-direction: column;
    background: var(--grey-light, #F5F5F5);
    border-radius: 15px;
    padding: 12px;
    gap: 12px;
    flex: 1;
}

.profile_history_form--content{
    border-top: solid 1px #19204E;
    padding-top: 12px;
}

