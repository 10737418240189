/* desktop screen size */
@media only screen and (min-width: 781px) {
    .home--container{
        display: flex;
        flex-direction: column;
        gap: 24px;
        flex: 1;
    }

    .home--listnews{
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}



/* tablet screen size */
@media only screen and (max-width: 780px) {
    .home--container{
        display: flex;
        flex-direction: column;
        gap: 24px;
        flex: 1;
    }

    .home--listnews{
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}