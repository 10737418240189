/* desktop screen size */
@media only screen and (min-width: 781px){
    .footer{
        display: flex;
        padding: 80px 32px;
        flex-direction: column;
        align-items: center;
        gap: 80px;
        background: var(--Light-Grey, #F4F4F4);
    }

    .footer--content{
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        justify-content: space-between;
    }

    .footer--content-one{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }

    .footer--content-two{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .footer--contact{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .footer--social{
        display: flex;
        align-items: flex-start;
        gap: 12px;
    }

    .footer--divider{
        height: 1px;
        align-self: stretch;
        background: var(--Black, #000);
    }

    .footer--credits{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        align-self: stretch;
    }

    .footer--credits-content{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
    }

    .footer--policy-terms{
        display: flex;
        align-items: flex-start;
        gap: 24px;
    }

    .icons{
        width: 32px;
        height: 32px;
        color: var(--Black, #000);        
    }
} 



@media only screen and (max-width: 780px){
    .footer{
        display: flex;
        width: 100%;
        box-sizing: border-box;
        padding: 24px 24px;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        background: var(--Light-Grey, #F4F4F4);
    }

    .footer--content{
        display: flex;
        align-items: flex-start;
        gap: 32px;
        align-self: stretch;
        justify-content: space-between;
        flex-direction: row;
    }

    .footer--content-one{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        flex: 1 0 0;
    }

    .footer--content-two{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        flex: 1 0 0;
    }

    .footer--social{
        display: flex;
        align-items: flex-start;
        gap: 12px;
    }

    .footer--divider{
        height: 1px;
        align-self: stretch;
        background: var(--Black, #000);
    }

    .footer--credits{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        align-self: stretch;
        flex-direction: column;
    }

    .footer--credits-content{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        flex-direction: column;
        gap: 32px
    }

    .footer--policy-terms{
        display: flex;
        align-items: flex-start;
        gap: 12px;
        flex-direction: column;
    }    

    .leagr-logo{
        width: 89px;
        height: 34px;
    }

    .icons{
        width: 24px;
        height: 24px;
        color: var(--Black, #000);        
    }

    .footer--contact{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
} 



@media only screen and (max-width: 490px){
    .footer{
        display: flex;
        width: 100%;
        box-sizing: border-box;
        padding: 24px 24px;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        background: var(--Light-Grey, #F4F4F4);
    }

    .footer--content{
        display: flex;
        align-items: flex-start;
        gap: 32px;
        align-self: stretch;
        justify-content: space-between;
        flex-direction: column;
    }

    .footer--content-one{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        flex: 1 0 0;
    }

    .footer--content-two{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        flex: 1 0 0;
    }

    .footer--social{
        display: flex;
        align-items: flex-start;
        gap: 12px;
    }

    .footer--divider{
        height: 1px;
        align-self: stretch;
        background: var(--Black, #000);
    }

    .footer--credits{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        align-self: stretch;
        flex-direction: column;
    }

    .footer--credits-content{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        flex-direction: column;
        gap: 32px
    }

    .footer--policy-terms{
        display: flex;
        align-items: flex-start;
        gap: 12px;
        flex-direction: column;
    }    

    .leagr-logo{
        width: 89px;
        height: 34px;
    }

    .icons{
        width: 24px;
        height: 24px;
        color: var(--Black, #000);        
    }

    .footer--contact{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

} 