.info_window_map_event--sport{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px
}

@media screen and (min-width: 781px){
    .info_window_map_event--container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }
    
    .info_window_map_event--head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        gap: 12px;
        align-self: stretch;
    }
    
    .info_window_map_event--head--left {
        display: flex;
        flex-direction: column;
    }
    
    .info_window_map_event--head--right {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
    
    .info_window_map_event--head--icon_frame{
        display: flex;
        justify-content: center;
        text-decoration: none;
        align-items: center;
        padding: 4px 8px ;
        border-radius: 25px;
        background: var(--blue-navy, #DF1B3F);
        width: fit-content;
    }
    
    .info_window_map_event--content{
        display: flex;
        justify-content: left;
        gap: 6px 12px;
        align-self: stretch;
        flex-wrap: wrap;
    }
    
} 

@media screen and (max-width: 780px){
    .info_window_map_event--container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }
    
    .info_window_map_event--head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        gap: 12px;
        flex: 1;
    }
    
    .info_window_map_event--head--left {
        display: flex;
        flex-direction: column;
    }
    
    .info_window_map_event--head--right {
        display: flex;
        flex-direction: row;
        justify-content: left;
    }
    
    .info_window_map_event--head--icon_frame{
        display: flex;
        text-decoration: none;
        align-items: center;
        padding: 4px 8px ;
        border-radius: 25px;
        background: var(--blue-navy, #DF1B3F);
        width: fit-content;
    }
    
    .info_window_map_event--content{
        display: flex;
        justify-content: left;
        gap: 6px 12px;
        align-self: stretch;
        flex-wrap: wrap;
    }
    
} 





