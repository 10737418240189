/* desktop screen size */
@media only screen and (min-width: 781px) {
    .privacy_police--container {
        padding: 184px 138px 64px 138px;
        background: var(--Blue-Light, #CCD9E5);
    }

    .privacy_police--content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        gap: 48px;
        /* align-self: stretch; */
    }

    .privacy_police--form{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 250px;
        max-width: 300px;
        gap: 12px;
    }

    .privacy_police--separator{
        display: flex;
        align-self: stretch;
        justify-content: center;
    }

    .privacy_police--form--input{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        gap: 8px;   
    }

    .privacy_police--image{
        width: 300px; 
        height: 400px; 
        object-fit: cover; 
        border-radius: 15px;
        object-position: 35% 0;
        box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.08);
    }
}

@media only screen and (max-width: 780px) {
    .privacy_police--container {
        display: flex;
        flex-direction: column;        
        justify-content: center;
        padding: 96px 12px 48px 12px;
        align-items: center;
        background: var(--Blue-Light, #CCD9E5);
    }

    .privacy_police--content {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 600px;
        gap: 24px;
    }

    .privacy_police--form{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 250px;
        gap: 12px;
        align-self: stretch;
        
    }

    .privacy_police--separator{
        display: flex;
        align-self: stretch;
        justify-content: center;
    }

    .privacy_police--image{
        width: 300px; 
        height: 150px; 
        object-fit: cover; 
        border-radius: 15px;
        object-position: 35% 0;
        box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.08);
    }

}





@import url(https://themes.googleusercontent.com/fonts/css?kit=OPeqXG-QxW3ZD8BtmPikfA);
ul.lst-kix_lwiih5pybr6a-2{list-style-type:none}
ul.lst-kix_lwiih5pybr6a-1{list-style-type:none}
ul.lst-kix_lwiih5pybr6a-4{list-style-type:none}
ul.lst-kix_lwiih5pybr6a-3{list-style-type:none}
ul.lst-kix_lwiih5pybr6a-0{list-style-type:none}
ul.lst-kix_e6s0nu96au8g-8{list-style-type:none}
.lst-kix_lwiih5pybr6a-4>li:before{content:"\0025cb   "}
.lst-kix_lwiih5pybr6a-5>li:before{content:"\0025a0   "}
ul.lst-kix_e6s0nu96au8g-5{list-style-type:none}
ul.lst-kix_e6s0nu96au8g-4{list-style-type:none}
ul.lst-kix_e6s0nu96au8g-7{list-style-type:none}
ul.lst-kix_e6s0nu96au8g-6{list-style-type:none}
.lst-kix_lwiih5pybr6a-7>li:before{content:"\0025cb   "}
ul.lst-kix_e6s0nu96au8g-1{list-style-type:none}
.lst-kix_e6s0nu96au8g-0>li:before{content:"  "}
ul.lst-kix_e6s0nu96au8g-0{list-style-type:none}
.lst-kix_e6s0nu96au8g-1>li:before{content:"\0025cb   "}
.lst-kix_lwiih5pybr6a-6>li:before{content:"\0025cf   "}
.lst-kix_lwiih5pybr6a-8>li:before{content:"\0025a0   "}
ul.lst-kix_e6s0nu96au8g-3{list-style-type:none}
ul.lst-kix_e6s0nu96au8g-2{list-style-type:none}
.lst-kix_e6s0nu96au8g-4>li:before{content:"\0025cb   "}
.lst-kix_e6s0nu96au8g-5>li:before{content:"\0025a0   "}
.lst-kix_e6s0nu96au8g-2>li:before{content:"\0025a0   "}
.lst-kix_e6s0nu96au8g-6>li:before{content:"\0025cf   "}
.lst-kix_w8115oe94vz0-8>li:before{content:"\0025a0   "}
.lst-kix_w8115oe94vz0-7>li:before{content:"\0025cb   "}
.lst-kix_e6s0nu96au8g-3>li:before{content:"\0025cf   "}
.lst-kix_w8115oe94vz0-6>li:before{content:"\0025cf   "}
.lst-kix_lwiih5pybr6a-3>li:before{content:"\0025cf   "}
.lst-kix_lwiih5pybr6a-2>li:before{content:"\0025a0   "}
.lst-kix_w8115oe94vz0-3>li:before{content:"\0025cf   "}
.lst-kix_w8115oe94vz0-5>li:before{content:"\0025a0   "}
.lst-kix_lwiih5pybr6a-1>li:before{content:"\0025cb   "}
ul.lst-kix_lwiih5pybr6a-6{list-style-type:none}
ul.lst-kix_lwiih5pybr6a-5{list-style-type:none}
ul.lst-kix_lwiih5pybr6a-8{list-style-type:none}
ul.lst-kix_lwiih5pybr6a-7{list-style-type:none}
.lst-kix_w8115oe94vz0-0>li:before{content:"  "}
.lst-kix_w8115oe94vz0-4>li:before{content:"\0025cb   "}
.lst-kix_e6s0nu96au8g-8>li:before{content:"\0025a0   "}
.lst-kix_lwiih5pybr6a-0>li:before{content:"  "}
.lst-kix_w8115oe94vz0-1>li:before{content:"\0025cb   "}
.lst-kix_e6s0nu96au8g-7>li:before{content:"\0025cb   "}
.lst-kix_w8115oe94vz0-2>li:before{content:"\0025a0   "}
ul.lst-kix_w8115oe94vz0-0{list-style-type:none}
ul.lst-kix_w8115oe94vz0-1{list-style-type:none}
ul.lst-kix_w8115oe94vz0-8{list-style-type:none}
ul.lst-kix_w8115oe94vz0-6{list-style-type:none}
ul.lst-kix_w8115oe94vz0-7{list-style-type:none}
ul.lst-kix_w8115oe94vz0-4{list-style-type:none}
ul.lst-kix_w8115oe94vz0-5{list-style-type:none}
ul.lst-kix_w8115oe94vz0-2{list-style-type:none}
ul.lst-kix_w8115oe94vz0-3{list-style-type:none}
li.li-bullet-0:before{margin-left:-18pt;white-space:nowrap;display:inline-block;min-width:18pt}
ol{margin:0;padding:0}
table td,table th{padding:0}
.c0{border-right-style:solid;padding-top:12px;border-top-width:0pt;border-bottom-color:#d9d9e3;border-right-width:0pt;padding-left:0pt;border-left-color:#d9d9e3;padding-bottom:0pt;line-height:1.15;border-right-color:#d9d9e3;border-left-width:0pt;border-top-style:solid;margin-left:36pt;border-left-style:solid;border-bottom-width:0pt;border-top-color:#d9d9e3;border-bottom-style:solid;orphans:2;widows:2;text-align:left;padding-right:0pt}
.c2{border-right-style:solid;padding-top:24px;border-top-width:0pt;border-bottom-color:#d9d9e3;border-right-width:0pt;padding-left:0pt;border-left-color:#d9d9e3;padding-bottom:0pt;line-height:1.15;border-right-color:#d9d9e3;border-left-width:0pt;border-top-style:solid;border-left-style:solid;border-bottom-width:0pt;border-top-color:#d9d9e3;border-bottom-style:solid;orphans:2;widows:2;text-align:left;padding-right:0pt}
.c1{color:#000000;font-weight:400;text-decoration:none;vertical-align:baseline;font-size:12pt;font-family:"Roboto";font-style:normal}
.c4{color:#000000;font-weight:400;text-decoration:none;vertical-align:baseline;font-size:11pt;font-family:"Arial";font-style:normal}
.c5{padding-top:0pt;padding-bottom:0pt;line-height:1.15;orphans:2;widows:2;text-align:left;height:11pt}
.c6{background-color:#ffffff;max-width:468pt;padding:24pt 24pt 24pt 24pt}
.c3{padding: 12px 12px;margin:0}
.title{padding-top:0pt;color:#000000;font-size:26pt;padding-bottom:3pt;font-family:"Arial";line-height:1.15;page-break-after:avoid;orphans:2;widows:2;text-align:left}
.subtitle{padding-top:0pt;color:#666666;font-size:15pt;padding-bottom:16pt;font-family:"Arial";line-height:1.15;page-break-after:avoid;orphans:2;widows:2;text-align:left}
li{color:#000000;font-size:11pt;font-family:"Arial"}p{margin:0;color:#000000;font-size:11pt;font-family:"Arial"}
h1{padding-top:20pt;color:#000000;font-size:20pt;padding-bottom:6pt;font-family:"Arial";line-height:1.15;page-break-after:avoid;orphans:2;widows:2;text-align:left}
h2{padding-top:18pt;color:#000000;font-size:16pt;padding-bottom:6pt;font-family:"Arial";line-height:1.15;page-break-after:avoid;orphans:2;widows:2;text-align:left}
h3{padding-top:16pt;color:#434343;font-size:14pt;padding-bottom:4pt;font-family:"Arial";line-height:1.15;page-break-after:avoid;orphans:2;widows:2;text-align:left}
h4{padding-top:14pt;color:#666666;font-size:12pt;padding-bottom:4pt;font-family:"Arial";line-height:1.15;page-break-after:avoid;orphans:2;widows:2;text-align:left}
h5{padding-top:12pt;color:#666666;font-size:11pt;padding-bottom:4pt;font-family:"Arial";line-height:1.15;page-break-after:avoid;orphans:2;widows:2;text-align:left}
h6{padding-top:12pt;color:#666666;font-size:11pt;padding-bottom:4pt;font-family:"Arial";line-height:1.15;page-break-after:avoid;font-style:italic;orphans:2;widows:2;text-align:left}