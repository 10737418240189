/* desktop screen size */
@media only screen and (min-width: 781px) {
    .app_navbar--container{
        padding: 120px 36px;
        background: var(--Blue-Dark, #19204E);
        width: 200px;
        position: fixed; 
        top: 0; 
        bottom: 0;
        height: 100%;
    }

    .app_navbar--menu{
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-self: stretch;
        align-items: flex-start;
        gap: 24px;
    }

    .app_navbar--menu--item{
        color: #FFF;
        display: flex;
        align-items: center;
        gap: 16px;
        background-color: none;
        align-self: stretch;
        text-decoration: none;
    }

    .app_navbar--menu--item--selected{
        display: flex;
        padding: 6px 0px 6px 6px;
        margin: 0px -65px 0px -15px;
        align-items: center;
        gap: 6px;
        align-self: stretch;
        border-radius: 50px;
        background: var(--Light-Grey, #FFF);
        text-decoration: none;
    }

    .app_navbar--menu--icon--selected{
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        border-radius: 25px;
        background: linear-gradient(136deg, #19204E 4.8%, #6980FD 99.39%);
        color: #FCB300;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .app_navbar--menu--icon{
        display: flex;
        align-items: center;
    }

    .app_navbar--buttons{
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-self: stretch;
        align-items: flex-start;
        gap: 12px;
    }

    .app_navbar--icons{
        width: 24px;
        height: 24px;
    }
}
