.profile_media--container{
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 24px;
}

.profile_media--content{
    display: flex;
    flex-direction: column;
    flex: 1;
}

.profile_media--actions{
    display: flex;
    justify-content: right;
}

.profile_media--content>div:nth-of-type(odd){
    background: var(--white, #FFF);
}


