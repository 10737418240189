/* desktop screen size */
@media only screen and (min-width: 781px) {
    .register--container {
        padding: 184px 138px 64px 138px;
        background: var(--Blue-Light, #CCD9E5);
    }

    .register--content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        gap: 48px;
        align-self: stretch;
    }

    .register--form{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-width: 250px;
        gap: 12px;
        align-self: stretch;
        
    }

    .register--separator{
        display: flex;
        align-self: stretch;
        justify-content: center;
    }

    .register--image{
        width: 300px; 
        height: 500px; 
        object-fit: cover; 
        border-radius: 15px;
        object-position: 35% 0;
        box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.08);
    }
}

@media only screen and (max-width: 780px) {
    .register--container {
        display: flex;
        flex-direction: column;        
        justify-content: center;
        padding: 96px 12px 48px 12px;
        align-items: center;
        gap: 12px;
        background: var(--Blue-Light, #CCD9E5);
    }

    .register--content {
        max-width: 700px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }

    .register--form{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 250px;
        gap: 12px;
        align-self: stretch;
        
    }

    .register--form--row{
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .register--separator{
        display: flex;
        align-self: stretch;
        justify-content: center;
    }

    .register--image{
        width: 100%; 
        height: auto; 
        object-fit: cover; 
        border-radius: 15px;
        object-position: 50% 50%;
        box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.08);
    }
}
