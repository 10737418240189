
@media screen and (min-width: 781px){
    .event_form--container{
        display: flex;
        flex-direction: column;
        gap: 24px
    }
}

@media screen and (max-width: 780px){
    .event_form--container{
        display: flex;
        flex-direction: column;
        gap: 12px
    }
}

.event_form--actions{
    display: flex;
    justify-content: space-between;
}