/* desktop screen size */
@media only screen and (min-width: 781px) {
    .login--container {
        padding: 184px 138px 64px 138px;
        background: var(--Blue-Light, #CCD9E5);
    }

    .login--content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        gap: 48px;
        /* align-self: stretch; */
    }

    .login--form{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 250px;
        max-width: 300px;
        gap: 12px;
    }

    .login--separator{
        display: flex;
        align-self: stretch;
        justify-content: center;
    }

    .login--form--input{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        gap: 8px;   
    }

    .login--image{
        width: 300px; 
        height: 400px; 
        object-fit: cover; 
        border-radius: 15px;
        object-position: 35% 0;
        box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.08);
    }
}

@media only screen and (max-width: 780px) {
    .login--container {
        display: flex;
        flex-direction: column;        
        justify-content: center;
        padding: 96px 12px 48px 12px;
        align-items: center;
        background: var(--Blue-Light, #CCD9E5);
    }

    .login--content {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 600px;
        gap: 24px;
    }

    .login--form{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 250px;
        gap: 12px;
        align-self: stretch;
        
    }

    .login--separator{
        display: flex;
        align-self: stretch;
        justify-content: center;
    }

    .login--image{
        width: 300px; 
        height: 150px; 
        object-fit: cover; 
        border-radius: 15px;
        object-position: 35% 0;
        box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.08);
    }

}