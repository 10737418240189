.profile--content{
    background: var(--grey, #D6D6D6);
    display: flex;
    flex: auto;
    padding: 12px;
}

.profile--user--menu_image{
    display: flex;
    flex-direction: column;
    width: 120px;
    height: 120px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.profile--user--image{
    width: 120px; 
    height: 120px; 
    object-fit: cover; 
    border-radius: 50%;
    object-position: 50% 50%;
}

.profile--menu--items{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.profile--menu--item{
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    background-color: none;
    align-self: stretch;
    text-decoration: none;
}

.profile--menu--item--selected{
    display: flex;
    padding: 8px 4px 8px 4px;
    align-items: center;
    justify-content: center;
    gap: 6px;
    align-self: stretch;
    background: var(--Light-Grey, #FFF);
    text-decoration: none;
}

@media screen and (min-width: 781px) {
    .profile--container{
        display: flex;
        border-radius: 15px;
        gap: 0px;
        flex: 1;
    }
    
    .profile--menu{
        background: var(--blue-navy, #19204E);
        width: 150px;
        min-width: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px 12px;
        gap: 48px;
    }
}

@media screen and (max-width: 780px){
    .profile--container{
        display: flex;
        flex-direction: column;
        border-radius: 15px;
        gap: 0px;
        flex: 1;
    }
    
    .profile--menu{
        background: var(--blue-navy, #19204E);
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 24px 12px;
        gap: 24px;
    }
}
