.player_item_list--container{
    display: flex;
    min-width: 200px;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 15px;
    background: var(--grey-light, #D6D6D6);
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
}

.player_item_list--head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: stretch;
}

.player_item_list--head--left {
    display: flex;
    flex-direction: column;
}

.player_item_list--head--right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.player_item_list--head--picture_frame{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.50);
}

@media screen and (min-width: 781px){
    .player_item_list--content{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 12px;
        align-self: stretch;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 780px){
    .player_item_list--content{
        display: flex;
        justify-content: left;
        gap: 12px;
        align-self: stretch;
        flex-wrap: wrap;
    }
}

