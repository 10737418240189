@media screen and (min-width: 781px) {

    .profile_media_item--container{
        display: flex;
        padding: 12px;
        flex-direction: column;
        justify-content: left;
        gap: 6px;
        background: var(--grey-light, #F5F5F5);
    }
}

@media screen and (max-width: 780px) {
    .profile_media_item--container{
        display: flex;
        padding: 8px;
        flex-direction: column;
        justify-content: left;
        gap: 6px;
        background: var(--grey-light, #F5F5F5);
    }
    
}

.profile_media_item--row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
}

.profile_media_item--set{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
}

.profile_media_item--set--actions{
    display: flex;
    flex-direction: row;
    justify-content: right;
    gap: 12px;
}
