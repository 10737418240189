/* desktop screen size */
@media only screen and (min-width: 781px) {
    .chat_list_item--container{
        display: flex;
        min-width: 335px;
        padding: 6px 12px;
        align-items: flex-start;
        gap: 12px;
        border-radius: 15px;
        background: var(--grey, #D6D6D6);
    }

    .chat_list_item--content{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        gap: 12px;
        flex: 1;
    }

    .chat_list_item--content--info{
        display: flex;
        justify-content: space-between;
        flex: 1;
        gap: 12px;
    }

    .chat_list_item--content--main{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 2;
    }

    .chat_list_item--content--detail{
        display: flex;
        justify-content: left;
        align-items: flex-end;
        flex: 1;
    }

    .chat_list_item--content--action{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .chat_list_item--icon_frame{
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        box-shadow: 2px 2px 6.667px 0px rgba(0, 0, 0, 0.50);
        overflow: hidden;
    }

    .chat_list_item--content--action--icon{
        width: 32px;
        height: 32px;
    }

    .chat_list_item--icon{
        color: #FFF;
        width: 32px;
        height: 32px;
    }
}



/* tablet screen size */
@media only screen and (max-width: 780px) {
    .chat_list_item--container{
        display: flex;
        padding: 6px 12px;
        align-items: flex-start;
        gap: 12px;
        border-radius: 15px;
        background: var(--grey, #D6D6D6);
    }

    .chat_list_item--content{
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: space-between;
        gap: 12px;
        flex: 1 0 0;
    }

    .chat_list_item--icon_frame{
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        box-shadow: 2px 2px 6.667px 0px rgba(0, 0, 0, 0.50);
        overflow: hidden;
        width: 43px;
        height: 43px;
    }

    .chat_list_item--content--info{
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .chat_list_item--content--main{
        display: flex;
        flex-direction: column;
        min-width: 230px;
    }

    .chat_list_item--content--detail{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .chat_list_item--content--action{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .chat_list_item--content--action--icon{
        width: 24px;
        height: 24px;
    }

    .chat_list_item--icon{
        color: #FFF;
        width: 24px;
        height: 24px;
    }
}