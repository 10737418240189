.profile_skill--container{
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;
}

.profile_skill--content{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
}

.profile_skill--action{
    display: flex;
    flex-direction: row;
    justify-content: right;
    gap: 24px;
}

