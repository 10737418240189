.map_marker--image--center{
    position: absolute;
    top: 4px;
    left: 6px;
    border-radius: 50px;
    overflow: hidden;
}

.map_marker--sport_icon{
    width: 28px;
    height: 28px;
    color: #FFF;
}

.map_marker--user_image{
    width: 28px;
    height: 28px;
    background-color: #FFF;
}



