.event_item_list--container{
    display: flex;
    min-width: 200px;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 15px;
    background: var(--grey-light, #D6D6D6);
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
}

.event_item_list--head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: stretch;
}

.event_item_list--head--left {
    display: flex;
    flex-direction: column;
}

.event_item_list--head--right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.event_item_list--head--icon_frame{
    display: flex;
    padding: 4px;
    border-radius: 25px;
    background: var(--blue-navy, #19204E);
}

@media screen and (min-width: 781px){
    .event_item_list--content{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 8px;
        row-gap: 4px;
        align-self: stretch;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 780px){
    .event_item_list--content{
        display: flex;
        justify-content: left;
        column-gap: 8px;
        row-gap: 4px;
        align-self: stretch;
        flex-wrap: wrap;
    }
}

